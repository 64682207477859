import React from 'react'
import { page_titles } from './SiteConstants'
import './Portifolio.css'

export const Portifolio = () => {
  return (
    <div className='custom-portifolio ' >

      <div className='container-fluid custom-main'> <h1 className='custom-color-font mt-4'>{page_titles.portifolio_page_title}</h1>

        <div><h5>{page_titles.portifolio_page_description}</h5></div>


        <div className='custom-main container-fluid mt-4' style={ {backgroundColor:'#204B6F'}}>

<div className='container-fluid d-flex flex-column align-items-center justify-content-center' >
<div className='row'>

  <div className='col'>
    <img src="/img/portifolio/site_cardannordeste.png" width='300px' height='156px'  alt="..." className='rounded'/>
    <div><span><a href='https://cardannordeste.com.br' target='_blank' rel="noreferrer" className='link-underline link-underline-opacity-0 link-warning'>www.cardannordeste.com.br</a></span></div>
  </div>

  <div className='col'>
    <img src="/img/portifolio/site_fabiojvalentim.png" width='300px' height='156px'  alt="..." className='rounded'/>
    <div><span>www.fabiojvalentim.com.br</span></div>
  </div>

</div>
</div>
</div>
      
      
      
      
      
      </div>
      

        
      
        
    </div>
    
    
  )
}
