import React from 'react'
import './Header.css'
import {site_main} from './SiteConstants'

export const Home = () => {
  return (
    <div className='App-main'>
      <div className='video-container'>
      
          <video className='object-fit-fill' autoPlay loop muted>
            <source src={site_main.video1080p} type="video/mp4" media='(min-width: 1920px)'/>
            <source src={site_main.video720p} type="video/mp4" media='(min-width: 480px)'/>
            <source src={site_main.video480p} type="video/mp4" media='(max-width: 479px)'/>
              
              Your Browser Doesnt support this video!
          </video>

        </div>
      
    </div>
  )
}
