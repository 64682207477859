
const site_header = {
    logo: '/logo192.png',
    author: "Fabio J. Valentim",
    description: "Full Stack Web Development",
}

const site_navigationbar = {

    home: "Home",
    services : "Services",
    portifolio: "Portifolio",
    certifications: "Certificates",
    courses: "Courses",

}

const site_main = {
    video480p: '/videos/fullstack_480p.mp4',
    video720p: '/videos/fullstack_720p.mp4',
    video1080p: '/videos/fullstack_1080p.mp4',
    img: '/img/'
}

const site_footer = {
    address: "Rua Quipapá",
    state: "Pernambuco",
    contact : "+55 (81) 9-96303366 ",
    phase: "",
    country: "Brazil",
    email: "eu@fabiojvalentim.com.br"
}

const page_titles = {
    home_page_title: "",
    certificate_page_tile: "My Certificates",
    certificate_page_description:
        "Here you will find some certificates used throughout my professional life," 
        +" working with IT since I was 15 years old as a student and from the age of 18 as a professional," 
        +" I have accumulated a lot of experience in these 22 years of career, training professionals,"
        +" supervising services, developing projects and contributing dedicated way to my work." ,
    
    portifolio_page_title: "My portifolio",
    portifolio_page_description:"Here starts my journey as web developer! I'll update with more real projects along the time",
    
    services_page_title: "My Services",
    services_page_description:
    "On this page, I describe some services I provide. Many years of experience"
    +" make me understand all processes of software development, from writing lines of code"
    +" until the user experience, considering all environment, creating precise "
    +" documentation for software use troubleshooting all layers, whether in on-premise or cloud environments",

    courses_page_title: "My courses",
    courses_page_description:"",
}

const page_services_contents = {
    section_1: {
        title: "My Services",

        
        service_1:{
        service_name: "IT Governance with COBIT 4.1",
        description:"Specialist in IT Governance, applying COBIT 4.1 practices to align technology and business, ensuring control and efficiency."},
        
        service_2:{
        service_name: "Computer Network Management",
        description:"This University Degree give me a solid ability to understand all necessary hardware and software acknowledgment to host on application on premise or on cloud with all pros and cons"},
    },

}

const page_certificates_contents ={
    section_1: {
        title: "Management",
        
        
        course_1:{
        certificate_name: "IT Governance with COBIT 4.1",
        description:"Specialist in IT Governance, applying COBIT 4.1 practices to align technology and business, ensuring control and efficiency."},
        
        course_2:{
        certificate_name: "Computer Network Management",
        description:"This University Degree give me a solid ability to understand all necessary hardware and software acknowledgment to host on application on premise or on cloud with all pros and cons"},
    },

    section_2: {
        title: "Web Development", 
        
        course_1:{
        certificate_name: "Meta Back-End Developer",
        description:"This Specialization was issued by Meta, creating Fullstack project using Django. Focusing on API concepts."},

        course_2:{
        certificate_name: "IBM Full Stack Web Developer",
        description:"This Specialization was issued by IBM, creating Professional Fullstack project using all cloud concepts like microcertificates, api gateway and cloudant database."},

        course_3:{
        certificate_name: "HTML5 , CSS3 and JS Web Dev",
        description:"This course was provided by Jonh Hopkins university, solid concepts using HTML5, CSS and JS creating a responsive website with no frameworks."},

        course_4:{
        certificate_name: "SPA with Angular JS",
        description:"This course is the base for all new advanced website and web applications. This concept is very important for enterprise level development."},

        course_5:{
        certificate_name: "Git and Github",
        description:"Nowdays is very top important to have version control of code, forking projects, making branches to work with teams."},

        course_6:{
        certificate_name: "Docker and kubernets",
        description:"Experience in Docker and Kubernetes, learning how to create, manage and scale containerized applications, optimizing infrastructure and ensuring high availability and resilience."},
        
        course_7:{
        certificate_name: "Front-End with React Dev",
        description:"Front-end development with React, creating dynamic and responsive user interfaces, improving user experience and optimizing web application performance."},
    
        course_8:{
        certificate_name: "Microcertificates and serverless",
        description:"Proficient in microservices and serverless architectures, learning to build scalable, efficient, and resilient applications while optimizing resource usage and operational costs."},
    
        course_9:{
        certificate_name: "Python for AI and Development",
        description:"Skilled in using Python for web development and artificial intelligence, creating robust, scalable web applications to enhance functionality and user experience."},

        course_10:{
        certificate_name: "Node and Express Essentials",
        description:"Experienced in using Node.js and Express for web development, building fast, scalable server-side applications, and creating robust APIs to enhance web functionality."},
    
        course_11:{
        certificate_name: "Dev App with SQL, DB and django",
        description:"Proficient in SQL, database management, and Django for application development, creating efficient, scalable web applications with robust data handling and seamless user experiences."},

        course_12:{
        certificate_name: "Django Web Framework",
        description:"Full web application using Django with management interface and defined modules"},

    },

    section_3: {
        title: "Infrastructure and support", 
        
        course_1:{
        certificate_name: "Microtik RB / rOS (MCTNA)",
        description:"Skilled in MikroTik RouterOS and RouterBOARD, certified with MTCNA, configuring and managing network solutions to ensure robust, efficient, and secure connectivity."},

        course_2:{
        certificate_name: "Radmin Especialist",
        description:"Expert in Radmin, providing secure, efficient remote access and network management solutions, ensuring seamless connectivity and support for IT infrastructure."},

        course_3:{
        certificate_name: "Integrated Administration",
        description:"Streamlined interdepartmental administration, harmonizing IT for seamless workflow integration across sectors."},

        course_4:{
        certificate_name: "Professional IT Support by Google",
        description:"Google IT Support course offers comprehensive training for professionals, covering diverse aspects of IT troubleshooting and maintenance."},
    
        course_5:{
        certificate_name: "Linux Essentials",
        description:"Linux Essentials course provides fundamental skills for navigating Linux systems, covering key concepts in administration and command-line usage."},
    
        course_6:{
        certificate_name: "Installing and configuring Hyper V",
        description:"Hyper-V Installation & Configuration course equips learners with essential skills for deploying and managing virtualized environments efficiently."},

        course_7:{
        certificate_name: "Virtualization with VMware",
        description:"VMware Virtualization course offers hands-on training in deploying and managing virtual environments, optimizing infrastructure efficiency."},
    
        course_8:{
        certificate_name: "Deploying and administering WSUS",
        description:"WSUS Deployment & Management course provides practical skills for implementing and overseeing WSUS infrastructure, ensuring effective patch management."},
    
        course_9:{
        certificate_name: "Study to business - IT Infrastructure",
        description:"IT Infrastructure course delivers comprehensive training on designing, implementing, and maintaining robust IT infrastructures for seamless operations."},
    
        course_10:{
        certificate_name: "SQL",
        description:"Comprehensive SQL course covers everything from basic queries to advanced database management, equipping with essential skills for data manipulation."},
        
        course_11:{
        certificate_name: "IT Infrastructure for PME",
        description:"Tailored infrastructure solutions for small and medium enterprises, optimizing efficiency, security, and scalability for seamless business operations."},
        
        course_12:{
        certificate_name: "IT Service Management based on ITIL V3",
        description:"Implementing IT Service Management aligned with ITIL V3 framework, enhancing service quality, efficiency, and alignment with business goals."},

        course_13:{
        certificate_name: "Security Information",
        description:"Enhancing cybersecurity through robust information security measures, safeguarding data integrity, confidentiality, and availability against evolving threats."},
        
        course_14:{
        certificate_name: "Remote Work",
        description:"Remote Work course equips individuals with essential skills for effective telecommuting, fostering productivity, collaboration, and work-life balance."},
            
        course_15:{
        certificate_name: "CRM",
        description:"CRM (Customer Relationship Management) course enhances proficiency in managing customer interactions, optimizing sales, and fostering long-term relationships."},
            
        course_16:{
        certificate_name: "Virtualization",
        description:"Virtualization course explores multiple virtualization platforms, empowering learners to deploy and manage virtual environments efficiently across diverse infrastructures."},
    }
    
    

}

export {site_header , site_main, site_navigationbar, site_footer, page_titles, page_certificates_contents, page_services_contents}
