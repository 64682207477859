import React from 'react'
import { Link } from 'react-router-dom'
import {site_navigationbar} from './SiteConstants'

import './NavigationBar.css'

export const NavigationBar = () => {
  return (
            <nav className="navbar navbar-expand-sm rounded-pill">
                <div className="container">
                        
                <button className="navbar-toggler mx-auto mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                    
                    <div className="collapse navbar-collapse custom-bg rounded-pill shadow px-2 justify-content-center custom-effects" id="navbarNav">
                        

                        <ul className="nav justify-content-center custom-bg rounded-pill ">
                            <li className="nav-item fs-5">
                                <Link to={"/"} className="nav-link"><span>{site_navigationbar.home}</span></Link>
                            </li>
                            
                            <li className="nav-item fs-5">
                                <Link to={"/my-services"} className="nav-link"><span>{site_navigationbar.services}</span></Link>
                            </li>
                            
                            <li className="nav-item fs-5">
                                <Link to={"/my-portifolio"} className="nav-link"><span>{site_navigationbar.portifolio}</span></Link>
                            </li>
                            
                            <li className="nav-item fs-5">
                                <Link to={"/my-certifications"} className="nav-link"><span>{site_navigationbar.certifications}</span></Link>
                            </li>

                            <li className="nav-item fs-5">
                                <Link to={"/my-courses"}className="nav-link"><span>{site_navigationbar.courses}</span></Link>
                            </li>    
                        </ul>

                    </div>
                </div>
            </nav> 
  )
}
