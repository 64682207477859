
import './App.css';
import { Header } from './components/Header';
import { Home } from './components/Home';
import { Footer } from './components/Footer';
import { NavigationBar } from './components/NavigationBar';

import { Services} from './components/Services'
import { Portifolio } from './components/Portifolio'
import { Certifications } from './components/Certifications'
import { Courses } from './components/Courses'

import { BrowserRouter , Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header/>
        <NavigationBar />

            <Routes>
              <Route path="/" element={ <Home /> } />
              <Route path="/my-services" element={ <Services /> } />
              <Route path="/my-portifolio" element={ <Portifolio /> } />
              <Route path="/my-certifications" element={ <Certifications /> } />
              <Route path="/my-Courses" element={ <Courses /> } />
            </Routes>

        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
