import React from 'react'
import { site_header } from './SiteConstants'

export const Header = () => {

    const openWhatsapp = () =>{
        const phone = '5581996303366'
        window.open(`https://wa.me/${phone}?text=Olá, te encontrek pelo site`)
    }

    const openTwitter = () =>{
        const url = 'https://twitter.com/ofabiojvalentim'
        window.open(url)
    }

    const openInstagram = () =>{
        const url = 'https://www.instagram.com/ofabiojvalentim'
        window.open(url)
    }

    const openLinkedIn = () =>{
        const url = 'https://www.linkedin.com/in/fabiojvalentim'
        window.open(url)
    }

  return (
    <header className="App-header p-2">
        
            <nav className="navbar" id="nav-brand">
                <div className="container-fluid">
                    {/* eslint-disable-next-line */}
                    <a className="navbar-brand d-flex align-items-left" href="#">
                        <img src="logo192.png" alt="Logo" width="64" height="64" className="d-inline-block align-span-center shadow"/>
                        <div className="ms-2 header-text">
                            <div className=""><span className='custom-txt-effects'>{site_header.author}</span></div>
                            <div className="fs-6"><span className='custom-txt-effects'><i>{site_header.description}</i></span></div>
                        </div>
                    </a>

                    <div className='d-flex'>
                    <button className='btn border border-warning m-1' onClick={openWhatsapp}><i className="bi bi-whatsapp"></i></button>    
                    <button className='btn border border-warning m-1' onClick={openTwitter}><i className="bi bi-twitter-x"></i></button>
                    <button className='btn border border-warning m-1' onClick={openInstagram}><i className="bi bi-instagram"></i></button>
                    <button className='btn border border-warning m-1' onClick={openLinkedIn}><i className="bi bi-linkedin"></i></button>
                    </div>
                    
                </div>        
            </nav>

            

        <div className='row'>
            
            </div>
    </header>
  )
}
