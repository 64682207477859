import React from 'react'
import {site_footer} from './SiteConstants'

export const Footer = () => {
  return (
    <footer className='App-footer p-1'>
      <div className='container-fluid'> 
        <div className='row'>
          <div className=' col-md-3 fs-6 '> 
            <ul className="nav justify-content-left">
              <li className="nav-item fs-6"><span><i className="bi bi-geo-fill"></i> Address: {site_footer.address} - State: {site_footer.state}</span></li>
              <li className="nav-item fs-6"><span><i className="bi bi-phone"></i> Phone: {site_footer.contact}</span></li>
            </ul> 
          </div>
        
          <div className='col-sm  fs-6'> <span><i className="bi bi-c-circle"></i> Copyright : Ofabiojvalentim (2024)</span></div>
      
          <div className='col-md-3 fs-6'><span> <i className="bi bi-envelope-check"></i> e-mail: {site_footer.email}</span> </div>
      
        </div>
      </div>
      
    </footer>
  )
}
