import React from 'react'
import './Services.css'
import { page_titles } from './SiteConstants'

export const Services = () => {
  return (
    <div className='custom-service'>

      <div className='container-fluid custom-main mt-4'><h1 className='custom-color-font'>{page_titles.services_page_title}</h1>
        
        <div><h5 className=''>{page_titles.services_page_description}</h5></div>


        <div className='m-6'>

        </div>

        <div className='row p-4 mt-4' style={ {backgroundColor:'#204B6F'}}>

          <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>

            <div className="card-effect card mt-4 shadow-lg bg-secondary  mb-3">
              <video className="card-video" width="234" height="156" loop autoPlay  muted>
                <source src="/videos/survaillance.mp4" type="video/mp4" />
              </video>

              <img src="/img/services/survaillance/1_sd.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
                Survaillance Systems
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>

                <p className='card-text'>
Video monitoring can inhibit criminal behavior within a business or residential environment. We carry out design, implementation and management.</p>
              </div>
            </div>

          </div>

          <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>

            <div className="card-effect card mt-4 shadow-lg bg-secondary  mb-3">
              <video className="card-video" width="234" height="156" loop autoPlay  muted>
                <source src="/videos/cybersecurity.mp4" type="video/mp4" />
              </video>

              <img src="/img/services/cybersecurity/1_sd.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
                Cyber Security
              </div>
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px' }}>

                <p className='card-text'>Many years of experience in security analysis, designing robust defenses, analyzing attack surfaces and developing security procedures.</p>
              </div>
            </div>

          </div>

          <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>

            <div className="card-effect card mt-4 shadow-lg bg-secondary  mb-3">
              <video className="card-video" width="234" height="156" loop autoPlay  muted>
                <source src="/videos/serveradmin.mp4" type="video/mp4" />
              </video>

              <img src="/img/services/servers/1_sd.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
                Server Administration
              </div>
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px' }}>

                <p className='card-text'>
With over 15 years of experience, I can configure, manage, monitor and scale the right server for all types of applications, whether on-premise, on-cloud or hybrid</p>
              </div>
            </div>

          </div>

          <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>

            <div className="card-effect card mt-4 shadow-lg bg-secondary  mb-3">
              <video className="card-video" width="234" height="156" loop autoPlay  muted>
                <source src="/videos/cabling.mp4" type="video/mp4" />
              </video>

              <img src="/img/services/cabling/1_sd.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
                Network Cabling
              </div>
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px' }}>

                <p className='card-text'>We develop structured cabling projects for business and residential customers, guaranteeing the best speed for their connection</p>
              </div>
            </div>

          </div>

          <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>

            <div className="card-effect card mt-4 shadow-lg bg-secondary  mb-3">
              <video className="card-video" width="234" height="156" loop autoPlay  muted>
                <source src="/videos/developer.mp4" type="video/mp4" />
              </video>

              <img src="/img/services/dev/1_sd.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
                Software Development
              </div>
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px' }}>

                <p className='card-text'>Application development in C#, Android in addition to Full Stack Web development, on the Front End with React, Django, HTML5, CSS3, JavaScript, Angular 2 (soon) and Back End with Node, Express and Django</p>
              </div>
            </div>

          </div>
          <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>

            <div className="card-effect card mt-4 shadow-lg bg-secondary  mb-3">
              <video className="card-video" width="234" height="156" loop autoPlay  muted>
                <source src="/videos/netadmin.mp4" type="video/mp4" />
              </video>

              <img src="/img/services/netadmin/1_sd.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
                Network Administration
              </div>
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px' }}>

                <p className='card-text'>All Network Assets and Passives, Active Directory, Connection Links, Physical and Virtual Access Permissions, complete management of your entire structure</p>
              </div>
            </div>

          </div>

          <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>

            <div className="card-effect card mt-4 shadow-lg bg-secondary  mb-3">
              <video className="card-video" width="234" height="156" loop autoPlay  muted>
                <source src="/videos/training.mp4" type="video/mp4" />
              </video>

              <img src="/img/services/training/1_sd.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
                Virtual Training
              </div>
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px' }}>

                <p className='card-text'>You need to distribute mass training, specific groups or individuals, be it an ERP, a simple application, a procedure, we carry out training of the most diverse types.</p>
              </div>
            </div>

          </div>




        </div>


      </div>


      
    </div>
  )
}
