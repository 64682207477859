import React from 'react'
import './Certifications.css'
import { page_titles , page_certificates_contents} from './SiteConstants'

export const Certifications = () => {
  return (
    <div className='custom-certificates'>

      <div className='container-fluid custom-main mt-4'><h1 className='custom-color-font'>{page_titles.certificate_page_tile}</h1>
      
        <div><h5>{page_titles.certificate_page_description}</h5></div>
      

      <div className='container-fluid mt-4' style={ {backgroundColor:'#204B6F'}}><h2>{page_certificates_contents.section_1.title}</h2>
        <div className='row p-4 justify-content-center'>

          {/* IT Governance with COBIT 4.1 */}
          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_it_governance_cobit41.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                  {page_certificates_contents.section_1.course_1.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_1.course_1.description}
                  </p>
                </div>
              </div>
          </div>

             {/* Management of Network Computer */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_higher_education.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_1.course_2.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_1.course_2.description}
                </p>
              </div>
            </div>
          </div>
          
        </div>

        
      </div>{/* endof management section */}

      <div><h2>{page_certificates_contents.section_2.title}</h2></div>

        <div className='row p-4'>

          {/* meta backend developer */}
          
          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_meta_back_end.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_1.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_1.description}
                </p>
              </div>
            </div>
          </div>

          {/* ibm fullstack web dev */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_ibm_fullstack.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_2.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_2.description}
                </p>
              </div>
            </div>
          </div>

          {/* html5 , css3 e js */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_html_css_js.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_3.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_3.description}
                </p>
              </div>
            </div>
          </div>

          {/* SPA with angular */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_angularjs.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_4.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_4.description}
                </p>
              </div>
            </div>
          </div>

          {/* Git and Github */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_git_github.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_5.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_5.description}
                </p>
              </div>
            </div>
          </div>

         

          {/* Docker and kubernets */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_container_kubernetes.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_6.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_6.description}
                </p>
              </div>
            </div>
          </div>

          {/* frontend with react */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_font_end_react_dev.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_7.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_7.description}
                </p>
              </div>
            </div>
          </div>


          {/* Microcertificates and serverless */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_microservices_serverless.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_8.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_8.description}
                </p>
              </div>
            </div>
          </div>

          {/* Python for AI and development */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_python_dev.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_9.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_9.description}
                </p>
              </div>
            </div>
          </div>

          {/* Node and Express Essentials */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_node_express.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_10.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_10.description}
                </p>
              </div>
            </div>
          </div>

          {/* Developing applications with sql, databases and django */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_dev_app_sql_db_django.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_11.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_11.description}
                </p>
              </div>
            </div>
          </div>

          {/* Django Web Framework */}

          <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
            <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
              
              <img src="/img/certificates/badge_django_web_framework.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
              <div className="card-header custom-color-font border-primary-subtle fs-6">
              {page_certificates_contents.section_2.course_12.certificate_name}
              </div>
              
              <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                <p className='card-text'>
                {page_certificates_contents.section_2.course_12.description}
                </p>
              </div>
            </div>
          </div>

          



        {/* endof row*/}
        </div>

        <div className='container-fluid mt-4' style={ {backgroundColor:'#204B6F'}}> <h2>{page_certificates_contents.section_3.title}</h2>
          <div className='row p-4'>

            {/* Mikrotik RB and OS */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_mikrotik.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_1.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_1.description}
                  </p>
                </div>
              </div>
            </div>

            {/* Radmin Specialist */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_radmin.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_2.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_2.description}
                  </p>
                </div>
              </div>
            </div>

            {/* Integrated Administration */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_administração_integrada.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_3.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_3.description}
                  </p>
                </div>
              </div>
            </div>

            {/* Google IT Support */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_it_support_google_specialization.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_4.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_4.description}
                  </p>
                </div>
              </div>
            </div>

            {/* Linux Essentials */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_linux_essentials.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_5.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_5.description}
                  </p>
                </div>
              </div>
            </div>

            {/* Installing and configuring Hyper V */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_hyper_v.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_6.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_6.description}
                  </p>
                </div>
              </div>
            </div>

            {/* Virtualisation with VMware */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_virtualisation_vmware_dell.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_7.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_7.description}
                  </p>
                </div>
              </div>
            </div>

            {/* deploying and administering wsus */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_implantando_administrando_wsus.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_8.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_8.description}
                  </p>
                </div>
              </div>
            </div>

            {/* deploying and administering wsus */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_students_to_business_it_infrastructure.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_9.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_9.description}
                  </p>
                </div>
              </div>
            </div>

            {/* SQL FULL */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_full_sql.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_10.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_10.description}
                  </p>
                </div>
              </div>
            </div>

            {/* IT Infrastructure for PME */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_it_infrastructure_for_pme.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_11.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_11.description}
                  </p>
                </div>
              </div>
            </div>

             {/* it service management based on itil v3 */}
             <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_itil_v3.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_12.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_12.description}
                  </p>
                </div>
              </div>
            </div>

            {/* Security Information */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_security_information.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_13.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_13.description}
                  </p>
                </div>
              </div>
            </div>

             {/* Remote Work */}
             <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_remote_work.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_14.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_14.description}
                  </p>
                </div>
              </div>
            </div>

             {/* CRM - Custumer Relationship Manager */}
             <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_crm.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_15.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_15.description}
                  </p>
                </div>
              </div>
            </div>

            {/* Virtualization Intel */}
            <div className='col-lg-2 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center'>
              <div className="card-effect-certificate card mt-4 shadow-lg bg-secondary  mb-3">
                
                <img src="/img/certificates/badge_virtualisation_intel.jpg" width='234px' height='156px' className="card-img-top" alt="..." />
                <div className="card-header custom-color-font border-primary-subtle fs-6">
                {page_certificates_contents.section_3.course_16.certificate_name}
                </div>
                
                <div className="card-body fs-6 text-white" style={{ maxWidth: '234px'}}>
                  <p className='card-text'>
                  {page_certificates_contents.section_3.course_16.description}
                  </p>
                </div>
              </div>
            </div>


          </div> {/* endof Infrastrucure and support row*/}
        </div> {/* endof Infrastructure and support */}

        
        

      {/* endof container-fluid custom-main*/}
      </div>

    {/* endof custom-service*/}    
    </div>
  )
}
